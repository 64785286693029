import React from 'react';
import './App.css';

import Navbar from 'react-bootstrap/Navbar';
import AppStoreLink from './AppStoreLink.js';

export default class FooterBar extends React.Component<Props, State> {

  render() {
    return (
        <div className="fixed-bottom">
          <Navbar className="Footer-bar justify-content-center">
            <div style={{
              width: '100%',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              {this._renderAppStoreLinksIfNeeded()}
              <small color="#fff" className="Footer-text" href="support">
                {"©2019 PearUp Inc. | Feedback, Questions, or Accessibility Issues: support@pearupchat.com"}
              </small>
              </div>
          </Navbar>
        </div>
    );
  }


  _renderAppStoreLinksIfNeeded() {
    if (window.screen.width >= 800) { return null; }
    return ( <AppStoreLink /> );
  };
}
