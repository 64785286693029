import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Support from './Support.js'
import Home from './Home.js'
import Verify from './Verify.js'
import Press from './Press.js'
import Investors from './Investors.js'

import { BrowserRouter as Router, Route } from "react-router-dom";

type Props = {}
type State = {}

export default class App extends React.Component<Props, State> {

  render() {
    return (
      <Router>
        <Route path="/" exact component={Home} />
        <Route path="/support" exact component={Support} />
        <Route path="/press" exact component={Press} />
        <Route path="/investors" exact component={Investors} />
        <Route path="/careers" exact component={Support} />
        <Route path="/verify" exact component={Verify} />
      </Router>
    );
  }
}
