import React from 'react';
import './App.css';

import TopNavigationBar from './TopNavigationBar';
import FooterBar from './FooterBar';

type Props = {}
type State = {}

export default class Investors extends React.Component<Props, State> {
  render() {
    return (
      <div className="App" style={{backgroundColor: '#FEEED9',}}>
        <nav>
          <div className="Desktop">
            <TopNavigationBar />
            <img className="Contact-image" alt="Contact PearUp" src={require('./assets/contact-us.png')} />
            <h3 style={{marginTop: '50px'}}>
              {'Email us at: '}
              <u>
                {'investor@pearupchat.com'}
              </u>
            </h3>
          </div>
          <FooterBar />
        </nav>
      </div>
    );
  }
}
