import React from 'react';
import './App.css';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from "react-router-dom";

import AppStoreLink from './AppStoreLink.js';

type Props = {}
type State = {}

export default class TopNavigationBar extends React.Component<Props, State> {

  _renderAppStoreLinksIfNeeded() {
    if (window.screen.width < 800) { return null; }
    return (
      <Navbar.Collapse className="justify-content-end">
        <AppStoreLink/>
      </Navbar.Collapse>
    );
  }

  render() {
    return (
      <Navbar className="Header-bar" bg="dark" variant="dark">
        <Navbar.Brand href="/">
          <img className="Pearup-desktop" alt="PearUp" src={require('./assets/pearup-mobile.png')} />
        </Navbar.Brand>
        <Nav className="justify-content-end">
          <Nav.Link href="support">Support</Nav.Link>
          <Nav.Link href="press">Press</Nav.Link>
          <Nav.Link href="investors">Investors</Nav.Link>
        </Nav>
        {this._renderAppStoreLinksIfNeeded()}
      </Navbar>
    );
  }
}
