import React from 'react';
import './App.css';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from "react-router-dom";

type Props = {}
type State = {}

export default class AppStoreLink extends React.Component<Props, State> {
  _onAppStoreClicked = (): void => {
     window.location = 'https://apps.apple.com/us/app/pearup-chat-dating-app/id1452411595'
  }

  _onPlayStoreClicked = (): void => {
    window.location = 'https://play.google.com/store/apps/details?id=com.pearapi.pearup'
  }

  render() {
    const imageStyle = {
      width: '35%',
      minWidth: '150px',
      maxWidth: '150px',
      paddingLeft: '10px',
      paddingRight: '10px',
    };
    return (
      <div>
        <img
          style={imageStyle}
          src={require('./assets/download-app-store.png')}
          alt=''
          onClick={this._onAppStoreClicked}
        />
        <img
          style={imageStyle}
          src={require('./assets/download-play-store.png')}
          alt=''
          onClick={this._onPlayStoreClicked}
        />
      </div>
    );
  }

}
