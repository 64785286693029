import React from 'react';
import './App.css';

import TopNavigationBar from './TopNavigationBar';
import FooterBar from './FooterBar';

export default class Home extends React.Component<Props, State> {
  render() {
    return (
        <div className="App">
          <nav>
            <div className="Desktop">
              <TopNavigationBar />
              <div className="Pearup-screenshots">
                <img className="Pearup-screenshots-image" alt="PearUp: Find your secret crush! See who's most interested!" src={require('./assets/a1.jpg')} />
                <img className="Pearup-screenshots-image" alt="Unlimited messaging. Text and photos." src={require('./assets/a3.jpg')} />
                <img className="Pearup-screenshots-image" alt="Powered by AI! Find real-time matches!" src={require('./assets/a4.jpg')} />
                <img className="Pearup-screenshots-image" alt="Stay Connected! Make meaningful connections" src={require('./assets/a5.jpg')} />
                <img className="Pearup-screenshots-image" alt="Dates, Friends and Fun. More than just a swipe." src={require('./assets/a6.jpg')} />
              </div>
            </div>
            <FooterBar />
          </nav>
        </div>
    );
  }
}
