import React from 'react';
import './App.css';

import FooterBar from './FooterBar';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

type Props = {}
type State = {
  accountIsValidated: boolean,
  submitted: boolean,
  errorMessage: string | null,
}

export default class Verify extends React.PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      accountIsValidated: false,
      submitted: false,
      errorMessage: null,
    }
  }

  componentDidMount() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const email = params.get('email');
    const token = params.get('token');
    this._validateUser(email, token);
  }

  _validateUser = async (email: string, token: string): Promise<Object> => {
    if (!email || !token) {
      console.error("Invalid Query String");
      this.setState({
        submitted: true,
        errorMessage: ""
      });
      return;
    }
    try {

      var res = await fetch(
        'https://v1.pearapi.com/verify?email=' + email + '&token=' + token, {
          headers: {Accept: 'application/json', 'Content-Type': 'application/json',},
      });
      const json = await res.json();
      if (json.error || !json.success) {
        this.setState({
          errorMessage: json.display_error || json.error || "",
          accountIsValidated: false,
          submitted: true,
        });
      } else {
        this.setState({
          errorMessage: "",
          accountIsValidated: true,
          submitted: true,
        });
      }
    } catch (err) {
      console.error("Error hitting verify endpoint: ", err, JSON.stringify(err), err.toString());
      this.setState({
        submitted: true,
        accountIsValidated: false,
      });
    }
  }

  _onAppStoreClicked = (): void => {
     window.location = 'https://apps.apple.com/us/app/pearup-chat-dating-app/id1452411595'
  }

  _onPlayStoreClicked = (): void => {
    window.location = 'https://play.google.com/store/apps/details?id=com.pearapi.pearup'
  }

  _renderSuccessIfNeeded() {
    if (this.state.accountIsValidated && this.state.submitted ) {
      return (
        <React.Fragment>
          <img className="Pearup-support-icon" alt='' src={require('./assets/checked.png')} />
          <h3 className="Support-email"> Congratulations! Your account is verified - Please open the app! </h3>
        </React.Fragment>
      );
    }
    return null;
  }

  _renderFailureIfNeeded() {
    if (this.state.submitted && !this.state.accountIsValidated) {
      var message  = this.state.errorMessage || "";
      message = message + "\nThere was an error verifying your account! Please try again later or try to sign up again. If the problem persists, please contact us at support@pearupchat.com";
      return (
        <React.Fragment>
          <img className="Pearup-support-icon" alt='' src={require('./assets/report.png')} />
          <h3 className="Support-email"> {message} </h3>
        </React.Fragment>
      );
    }
    return null;
  }

  render() {
    return (
      <div style={{backgroundColor: '#FEAA2C'}}>
        <Helmet>
            <style>{'body { background-color: #FEAA2C; }'}</style>
        </Helmet>
        <div className="Desktop">
          <img
            style={{
              width: '80vw',
              maxWidth: '500px',
              maxHeight: '146.7px',
              paddingTop: '1vh',
            }}
            alt=''
            src={require('./assets/pearup-mobile.png')}
          />
          <img
            style={{
              width: '80vw',
              maxWidth: '500px',
              maxHeight: '146.7px',
              paddingTop: '1vh',
            }}
            alt=''
            src={require('./assets/subtitle.png')}
          />
          {this._renderSuccessIfNeeded()}
          {this._renderFailureIfNeeded()}
          <div className="Footer">
            <img
              className="Mobile-store-button"
              src={require('./assets/download-app-store.png')}
              alt=''
              onClick={this._onAppStoreClicked}
            />
            <img
              className="Mobile-store-button"
              src={require('./assets/download-play-store.png')}
              alt=''
              onClick={this._onPlayStoreClicked}
            />
          </div>
          <div className="Support">
            <Link to="/support" style={{ color: 'white' }}>
              <b> Contact Us </b>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
